import { IActivity } from './iactivity';


export interface IActivityTypeCodeableConcept {
    activityTypeCodeableConceptIdentify: string;
    activityIdentifier: string;
    activity: IActivity[];
    type: string;
    tenantId: Number;
    language: string;
}
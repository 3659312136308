import { IAcceptanceCodeableConcept } from './iacceptance-codeable-concept';
import { IAspectCodeableConcept } from './iaspect-codeable-concept';
import { IDecubitusPositionCodeableConcept } from './idecubitus-position-codeable-concept';
import { IDiuresisColorCodeableConcept } from './idiuresis-color-codeable-concept';
import { IDiuresisQuantityCodeableConcept } from './idiuresis-quantity-codeable-concept';
import { IEvacuationQuantityCodeableConcept } from './ievacuation-quantity-codeable-concept';
import { IPractitioner } from './ipractitioner';

export interface IProgress {

  activityIdentifier: string;
  progressIdentifier: string;
  activityDetailIdentifier: string;
  performerPractitionerIdentifier?: string;
  practitioners?: IPractitioner[]
  time: Date;
  ActivityContributors?: any // prisma table name
  contributorOnActivity: IPractitioner[]; // TODO: entender a diferença entre IContributorOnActivity e IActivityContributor;
  // Dados estruturados personalizados
  text: string | null;
  accept: string;
  codeAcceptanceIdentifier: string;
  acceptanceCodeableConcept: IAcceptanceCodeableConcept[];
  agitation: string;
  codeAspectIdentifier: string;
  aspectCodeableConcept: IAspectCodeableConcept[];
  codeDiuresisColorIdentifier: string;
  diuresisColorCodeableConcept: IDiuresisColorCodeableConcept[];
  codeDiuresisQuantityIdentifier: string;
  diuresisQuantityCodeableConcept: IDiuresisQuantityCodeableConcept[];
  choke: string;
  codeEvacuationQuantityIdentifier: string;
  evacuationQuantityCodeableConcept: IEvacuationQuantityCodeableConcept[]
  comments: string;
  codeDecubitusPositionIdentifier: string;
  decubitPositionCodeableConcept: IDecubitusPositionCodeableConcept[]
  somnolence: string;
  leak: string;
  externalSupport: string;
  externalSupportDescription: string;
  hadHelp: boolean;
  fecesLeakness: string;
  urineLeakness: string;
  wasPerformed: string;
}

import { IActivityDetail } from './iactivity-detail';
import { ICarePlan } from './icare-plan';
import { IPatientOnCarePlan } from './ipatient-on-careplan';

export interface IFhirPatient {
  patientIdentifier: string;
  active: boolean;
  patientName: string;
  millenniumId: number;
  medlogicId: number;
  floor: number;
  block: string;
  room: number;
  bed: string;
  dateBirth: Date;
  cpf: string;
  rg: string;
  nameMother: string;
  nameFather: string;
  tenantId: number;
  language: string;
  patientOnCarePlan: IPatientOnCarePlan[];
  activityDetail: IActivityDetail[];
  carePlan: ICarePlan[];
}
